var container;
var slider;
var slides;
var buttons;
var currentPosition = 0;
var currentMargin = 0;
var slidesPerPage = 0;

function destinationAdvice(){
    // SOF Slider 28-8-24 
   container = document.getElementById('container');
   slider = document.getElementById('slider');
   slides = document.getElementsByClassName('slide').length;
   buttons = document.getElementsByClassName('arrow-btn');
  window.addEventListener("resize", checkWidth);

  if (slides < 4) {
    slider.classList.add('center-slide');
  } else {
    slider.classList.remove('center-slide');
  }

  var containerWidth = container.offsetWidth;
  setParams(containerWidth);

  // EOF Slider 
}

function checkWidth() {
    containerWidth = container.offsetWidth;
    setParams(containerWidth);
  }

function setParams(w) {
    slides = document.getElementById("slider").getElementsByClassName("nav-item").length;
    var slidesCount = slides - slidesPerPage;
    
    if (w < 551) {
      slidesPerPage = 1;
    } else if (w < 901) {
      slidesPerPage = 2;
    } else if (w < 1101) {
      slidesPerPage = 3;
    } else {
      slidesPerPage = 4;
    }

    slidesCount = slides - slidesPerPage;
    
    if (currentPosition > slidesCount) {
      currentPosition = slidesCount;
    }
    currentMargin = -currentPosition * (100 / slidesPerPage);
    slider.style.marginLeft = currentMargin + '%';

    // if(slidesCount < 0){
    //   currentPosition = currentMargin
    // }

    if (currentPosition > 0) {
      buttons[0].classList.remove('inactive');
    } else {
      buttons[0].classList.add('inactive');
    }
    if (currentPosition < slidesCount) {
      buttons[1].classList.remove('inactive');
    } else {
      buttons[1].classList.add('inactive');
    }

  }

function slideLeftDA() {
    if (currentPosition > 0) {
      currentPosition--;
      currentMargin += (100 / slidesPerPage);
      slider.style.marginLeft = currentMargin + '%';
      if (currentPosition === 0) {
        buttons[0].classList.add('inactive');
      }
      buttons[1].classList.remove('inactive');
    }
  }

  function slideRightDA() {
    var slidesCount = slides - slidesPerPage;
    if (currentPosition < slidesCount) {
      currentPosition++;
      currentMargin -= (100 / slidesPerPage);
      slider.style.marginLeft = currentMargin + '%';
      if (currentPosition === slidesCount) {
        buttons[1].classList.add('inactive');
      }
      buttons[0].classList.remove('inactive');
    }
  }