// SOF Scroll tab right & left 
// This js is used in hotel,flight

var container;
var slider;
var slides;
var buttons;
var currentPosition;
var currentMargin;
var slidesPerPage;
var slidesCount;
var containerWidth;
var prevKeyActive;
var nextKeyActive;

function initial() {
    container = document.getElementById('container');
    slider = document.getElementById('slider');
    slides = document.getElementsByClassName('slide').length;
    buttons = document.getElementsByClassName('arrow-btn');

    currentPosition = 0;
    currentMargin = 0;
    slidesPerPage = 0;
    slidesCount = slides - slidesPerPage;
    containerWidth = container.offsetWidth;
    prevKeyActive = false;
    nextKeyActive = true;

      window.addEventListener("resize", checkWidth);

      function checkWidth() {
          containerWidth = container.offsetWidth;
          setParams(containerWidth);
      }

      function setParams(w) {
          if (w < 551) {
              slidesPerPage = 1;
          } else if (w < 901) {
              slidesPerPage = 2;
          } else if (w < 1101) {
              slidesPerPage = 3;
          } else {
              slidesPerPage = 4;
          }
          slidesCount = slides - slidesPerPage;
          if (currentPosition > slidesCount) {
              currentPosition = slidesCount;
          }
          if (slides <= 3) {
              currentPosition = 0;
              currentMargin = 0;
          } else {
              currentMargin = -currentPosition * (100 / slidesPerPage);
          }
          slider.style.marginLeft = currentMargin + '%';
          updateButtons();
      }

      function updateButtons() {
          if (currentPosition > 0) {
            if(buttons && buttons[0] && buttons[0].classList) {
              buttons[0].classList.remove('inactive');
            }
          } else {
                if(buttons && buttons[0] && buttons[0].classList) {
                    buttons[0].classList.add('inactive');
                }
          }
          if (currentPosition < slidesCount) {
            if(buttons && buttons[0] && buttons[0].classList) {
              buttons[1].classList.remove('inactive');
            }
          } else {
            if(buttons && buttons[0] && buttons[0].classList) {
              buttons[1].classList.add('inactive');
            }
          }
      }

      if (slides < 4) {
        slider.classList.add('center-slide');
      } else {
        slider.classList.remove('center-slide');
      }
      
      setParams(containerWidth);
    }
      function slideRight() {
          if (currentPosition > 0) {
              currentPosition--;
              currentMargin += (100 / slidesPerPage);
              slider.style.marginLeft = currentMargin + '%';
              if (currentPosition === 0) {
                  buttons[0].classList.add('inactive');
              }
              buttons[1].classList.remove('inactive');
          }
      }

      function slideLeft() {
          if (currentPosition < slidesCount) {
              currentPosition++;
              currentMargin -= (100 / slidesPerPage);
              slider.style.marginLeft = currentMargin + '%';
              if (currentPosition === slidesCount) {
                  buttons[1].classList.add('inactive');
              }
              buttons[0].classList.remove('inactive');
          }
      }
    
// EOF Scroll tab right & left  